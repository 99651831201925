// banner管理
import request from '@/utils/util.js'

// 新增、编辑
export function saveBannerAPI(data) {
	return request.post('/banner/save', data)
}

// 列表
export function getBannerDataAPI(data) {
	return request.get('/banner/list', data)
}
