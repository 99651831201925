<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item label="状态" prop="port">
        <el-select v-model="queryForm.port" placeholder="请选择">
          <el-option label="院方护理端" :value="1"></el-option>
          <el-option label="用户端" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column label="banner" v-slot="{ row }">
        <el-image :src="row.image[0]" :preview-src-list="row.image" fit="scale-down"
          style="width: 100px; height: 100px">
        </el-image>
      </el-table-column>
      <el-table-column prop="start_time" label="开始时间"></el-table-column>
      <el-table-column prop="end_time" label="结束时间"></el-table-column>
      <el-table-column label="展示终端" v-slot="{ row }">
        <span>{{ row.port === 1 ? '院方护理端' : '用户端' }}</span>
      </el-table-column>
      <el-table-column label="状态" v-slot="{ row }">
        <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
        <el-tag v-else type="info" size="small">禁用</el-tag>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getBannerData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑公告的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="500px" top="8vh">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="Banner" prop="image">
          <el-upload :action="UPLOAD_URL" :file-list="bannerFileList" :before-upload="handleBannerBeforUpload"
            :on-success="handleBannerSuccess" :on-remove="handleBannerRemove" :on-exceed="handleFileExceed"
            list-type="picture-card">
            <i class="el-icon-plus"></i>
            <div slot="tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="开始时间" prop="start_time">
          <el-date-picker v-model="form.start_time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
            class="el-input_inner--rewrite"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_time">
          <el-date-picker v-model="form.end_time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
            class="el-input_inner--rewrite"></el-date-picker>
        </el-form-item>
        <el-form-item label="展示终端" prop="port">
          <el-radio-group v-model="form.port">
            <el-radio :label="1">院方护理端</el-radio>
            <el-radio :label="2">用户端</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  import {
    saveBannerAPI,
    getBannerDataAPI,
  } from '@/api/banner.js'

  export default {
    name: 'Banner',
    data() {
      return {
        UPLOAD_URL,

        queryForm: {
          page: 1,
          port: 2,
        },
        // 表格相关
        tableData: [],
        total: 0,
        pageSize: 0,
        curRowData: null,
        tableLoading: false,
        // 上传文件相关
        bannerFileList: [],
        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          title: '',
          image: '',
          status: 1,
          start_time: '',
          end_time: '',
          port: 2
        },
        rules: {
          title: [{
            required: true,
            message: '标题不能为空',
            trigger: 'blur'
          }],
          image: [{
            required: true,
            message: 'Banner不能为空',
            trigger: 'change'
          }],
          start_time: [{
            required: true,
            message: '开始时间不能为空',
            trigger: 'change'
          }],
          end_time: [{
            required: true,
            message: '开始时间不能为空',
            trigger: 'change'
          }],
        }
      }
    },
    created() {
      this.getBannerData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.dialogTitle = '新增'
        this.showDialog = true
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getBannerData()
      },
      closeDialog() {
        this.bannerFileList = []
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显 image
        this.bannerFileList = this.curRowData.image.map(item => {
          return {
            url: item
          }
        })
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 文件超出个数限制时的钩子
      handleFileExceed() {
        this.$message.warning('上传失败，文件数量超出限制')
      },
      // banner上传之前
      handleBannerBeforUpload(file) {
        if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
          this.$message.error('文件格式错误')
          return false
        }
      },
      // banner上传成功
      handleBannerSuccess(res, file, fileList) {
        this.bannerFileList = fileList
      },
      // banner删除
      handleBannerRemove(file, fileList) {
        this.bannerFileList = fileList
      },
      // 提交按钮
      handleSubmit() {
        this.submitting = true
        // 设置 image
        const paths = this.bannerFileList.map(item => {
          if (item.response) return item.response.data.url
          else return item.url
        })
        this.form.image = paths.toString()
        // 验证表单
        this.$refs.form.validate(isValid => {
          if (!isValid) return this.submitting = false

          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveBannerAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getBannerData()
          }).finally(() => this.submitting = false)
        })
      },
      // 获取通知数据
      getBannerData() {
        this.tableLoading = true

        getBannerDataAPI(this.queryForm).then(res => {
          this.tableData = res.data.map(item => {
            item.port = parseInt(item.port)
            item.status = parseInt(item.status)
            const isSingle = item.image.indexOf(',') < 0

            if (isSingle) item.image = [item.image]
            else item.image = item.image.split(',')
            return item
          })
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style scoped>
</style>